/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 100%;
}
.desk.small {
  max-width: 80%;
  width: 68.89140271%;
}
.cb-page-layout2 .cb-multimood {
  display: block;
}
.cb-home {
  margin-top: 100px;
  width: 250px;
}
.open-navi {
  width: 46px;
  height: 43px;
}
#view .cb-page-layout1 .main div.link a.open {
  margin-left: -31px;
  width: 62px;
  height: 52px;
}
.cb-page-layout3 .content {
  padding-bottom: 92px;
}
.cb-page-layout3 .cb-page-title {
  margin-top: 60px;
  line-height: 1.5;
}
.cb-page-layout1 .cb-slides {
  min-height: 595px !important;
}
#root .cb-page-layout3 .main .pict {
  margin: 0 auto;
  float: none;
  max-width: 250px;
}
.printwidth {
  padding-bottom: 0;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.64203612%;
  margin-left: 1.64203612%;
}
.area .part,
.area > .grid table {
  margin-right: 1.64203612%;
  margin-left: 1.64203612%;
  width: 96.71592775%;
}
.area .tiny {
  width: 46.71592775%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96.71592775%;
}
.cb-page-layout1 .main,
.cb-page-layout3 .main {
  width: 100%;
}
.cb-page-layout1 .main > .unit,
.cb-page-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .main h2,
.cb-page-layout3 .main h2,
.cb-page-layout1 .main .foot,
.cb-page-layout3 .main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout1 .main .part,
.cb-page-layout3 .main .part,
.cb-page-layout1 .main > .grid table,
.cb-page-layout3 .main > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .main > .slim .part,
.cb-page-layout3 .main > .slim .part,
.cb-page-layout1 .main > .slim.grid table,
.cb-page-layout3 .main > .slim.grid table {
  width: 100%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
h1,
h2 {
  font-size: 39px;
}
div.text p.norm {
  font-size: 20px;
}
.cb-page-layout3 div.text p.norm {
  font-size: 20px;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 10% !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.shop,
#disp.cb-shoppingcart {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */